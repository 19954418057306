// import React, { useContext } from "react";
import React from "react";
import Fade from "react-reveal/Fade";
import { Container } from "react-bootstrap";
// import PortfolioContext from "../../context/context";
// import Title from "../Title/Title";
import BadgeImg from "../Image/BadgeImg";

const Contact = () => {
  // const { contact } = useContext(PortfolioContext);
  // const { cta, btn, email } = contact;

  return (
    <section id="contact">
      <Container>
        {/* <Title title="Contact" /> */}
        <Fade bottom duration={1000} delay={800} distance="30px">
          <div className="contact-wrapper">
            <p className="contact-wrapper__text">
              3초, 간편 세탁 삶의 질이 올라가는 <br />
              런드리홈즈 전문가와 함께하기
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--resume"
              href="https://play.google.com/store/apps/details?id=com.laundryhomes"
            >
              <BadgeImg alt="contact picture" filename="google_badge.png" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--resume"
              href="https://apps.apple.com/us/app/id1526137178/"
            >
              <BadgeImg alt="contact picture" filename="apple_badge.png" />
            </a>
          </div>
        </Fade>
      </Container>
    </section>
  );
};

export default Contact;

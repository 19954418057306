// import React, { useContext, useState, useEffect } from "react";
import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Container, Row, Col } from "react-bootstrap";
// import Title from "../Title/Title";
import AboutImg from "../Image/AboutImg";
// import PortfolioContext from "../../context/context";

const About = () => {
  // const { about } = useContext(PortfolioContext);
  // const { img, paragraphOne, paragraphTwo, paragraphThree, resume } = about;
  // const { img } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        {/* <Title title="About Me" /> */}
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="about-wrapper__image">
                <AboutImg alt="profile picture" filename="master.png" />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text1">
                  45년 경력
                  <br />
                  세탁기능사의 철학
                </p>
                <hr className="about-wrapper__info-textline" />
                <p className="about-wrapper__info-text2">
                  8년 업력의 회사와 함께하는 런드리홈즈
                  <br />
                </p>
                <p className="about-wrapper__info-text3">
                  런드리홈즈는 작은 세탁소를 시작으로 2011년 세탁 기능사들이
                  <br />
                  모여 고객들의 니즈를 충족시키기 위해 만들어진 브랜드 입니다.
                  <br />
                  새로운 시대에 맞춰 세탁서비스도 변화하고 있습니다.
                  <br />
                  런드리홈즈는 세탁서비스방식의 변화에만 치중하지 않고
                  <br />
                  ‘가족을 생각하는 마음 그대로’ 라는 슬로건 아래
                  <br />
                  세탁의 본질에 편리함을 더한 서비스를 제공 할 것입니다.
                  <br />
                </p>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;

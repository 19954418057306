// import React, { useContext } from "react";
import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-scroll";
// import PortfolioContext from "../../context/context";
// import GithubButtons from "../GithubButtons/GithubButtons";
// import { githubButtons } from "../../mock/data";

const Footer = () => {
  // const { footer } = useContext(PortfolioContext);
  // const { networks } = footer;
  // const { isEnabled } = githubButtons;

  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <span className="back-to-top">
          <Link to="hero" smooth duration={1000}>
            <i className="fa fa-angle-up fa-2x" aria-hidden="true" />
          </Link>
        </span>
        {/* <div className="social-links">
          {networks &&
            networks.map((network) => {
              const { id, name, url } = network;
              return (
                <a
                  key={id}
                  href={url || "https://github.com/cobidev/gatsby-simplefolio"}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label={name}
                >
                  <i className={`fa fa-${name || "refresh"} fa-inverse`} />
                </a>
              );
            })}
        </div> */}
        <hr />
        <p className="footer__text">
          상호명 : 런드리홈즈(LaundryHome&#39;s) | 대표이름 : 나영애
          <br />
          개인정보관리책임자 : 나영애 | 전화번호 : 031-221-3825, 010-9076-0900
          <br />
          이메일 : shinnanemarket@gmail.com
          <br />
          사업장주소 : 화성시 병점동로 78, 1층 106호(병점동,유진클라우스) (우편번호 18403)
          <br />
          사업자등록번호 : 492-14-02295 | 통신판매번호 : 제 2023-화성동부-0702 호
          {/* <br />© {new Date().getFullYear()} - Template developed by{" "} */}
          {/* <a href="https://github.com/cobidev" target="_blank" rel="noopener noreferrer">
            Jacobo Martínez
          </a> */}
        </p>

        {/* {isEnabled && <GithubButtons />} */}
      </Container>
    </footer>
  );
};

export default Footer;
